import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContextDataResolver } from "./context-data.resolver";
import { ThemeComponent } from "./theme.component";

const routes: Routes = [
  {
    path: '',
    component: ThemeComponent,
    resolve: {
      rsContextData: ContextDataResolver
    },
    children: [
      { path: "dashboard", loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: "notification/:type", loadChildren: () => import('./pages/notificationDashboard/notificationDashboard.module').then(m => m.NotificationDashboardModule) },
      { path: "data-ware-house", loadChildren: () => import('./pages/dataWareHouse/dataWareHouseMain.module').then(m => m.DataWareHouseMainModule) },
      { path: "data-pipeline", loadChildren: () => import('./pages/dataPipeline/dataPipelineMain.module').then(m => m.DataPipelineMainModule) },
      { path: "user-group", loadChildren: () => import('./pages/userGroup/userGroupMain.module').then(m => m.UserGroupMainModule) },
      { path: "access-control", loadChildren: () => import('./pages/accessControl/accessControl.module').then(m => m.AccessControlModule) },
      { path: "admin-setting", loadChildren: () => import('./pages/adminSetting/adminSettingMain.module').then(m => m.AdminSettingMainModule) },
      { path: "data-comparator", loadChildren: () => import('./pages/dataComparator/dataComparatorMain.module').then(m => m.DataComparatorMainModule) },
      { path: "crawler", loadChildren: () => import('./pages/crawler/crawler.module').then(m => m.CrawlerMainModule) },
      { path: "content-hub", loadChildren: () => import('./pages/contentHub/contentHubMain.module').then(m => m.ContentHubMainModule) },
      { path: "chatbot", loadChildren: () => import('./pages/chatbot/chatbotMain.module').then(m => m.ChatbotMainModule) },
      { path: "llm-model", loadChildren: () => import('./pages/llmModel/chatbotMain.module').then(m => m.LLMModelMainModule) },
      { path: "account", loadChildren: () => import('./pages/account/accountMain.module').then(m => m.AccountMainModule) },
      { path: "smart-query", loadChildren: () => import('./pages/smartQuery/smart-query.module').then(m => m.SmartQueryModule) },
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    ContextDataResolver,
  ]
})
export class ThemeRoutingModule { }
