export enum EnumFunctionList {
  DataWarehousePage = "DATA_WAREHOUSE_PAGE",

  SimpleQueryPage = "SIMPLE_QUERY_PAGE",
  ApiFetching = "API_FETCHING",

  HistoryPage = "HISTORY_PAGE",
  EntityHistory = "ENTITY_HISTORY",
  DownloadErrorReport = "DOWNLOAD_ERROR_REPORT",
  FileHistory = "FILE_HISTORY",
  DownloadFile = "DOWNLOAD_FILE",
  DeleteFile = "DELETE_FILE",
  ApiJsonHistory = "API_JSON_HISTORY",
  DownloadApiJsonLog = "DOWNLOAD_API_JSON_LOG",

  AddEntity = "ADD_ENTITY",

  EditEntity = "EDIT_ENTITY",
  SubmitForValidationButton = "SUBMIT_FOR_VALIDATION_BUTTON",
  RetrySubmissionButton = "RETRY_SUBMISSION_BUTTON",
  ViewDataSource = "VIEW_DATA_SOURCE",
  EditDataSource = "EDIT_DATA_SOURCE",
  EnableDataset = "ENABLE_DATASET",
  SourceByGoogleSheet = "SOURCE_BY_GOOGLE_SHEET",
  SourceByHttpRequest = "SOURCE_BY_HTTP_REQUEST",
  SourceBySftp = "SOURCE_BY_SFTP",
  SourceByApiFile = "SOURCE_BY_API_FILE",
  SourceByDBConnector = "SOURCE_BY_DB_CONNECTOR",
  EnableFileUpload = "ENABLE_FILE_UPLOAD",
  EnableApiJson = "ENABLE_API_JSON",
  EnableRealTimeApi = "ENABLE_REAL_TIME_API",
  BasicRealTimeApi = "BASIC_REAL_TIME_API",
  WoocommerceWebhook = "WOOCOMMERCE_WEBHOOK",
  ShopifyWebhook = "SHOPIFY_WEBHOOK",
  EnableTrackerApi = "ENABLE_TRACKER_API",
  ViewDataSchema = "VIEW_DATA_SCHEMA",
  EditDataSchema = "EDIT_DATA_SCHEMA",

  DeleteEntity = "DELETE_ENTITY",

  ViewEntityRelation = "VIEW_ENTITY_RELATION",
  AddEntityRelation = "ADD_ENTITY_RELATION",
  EditEntityRelation = "EDIT_ENTITY_RELATION",
  DeleteEntityRelation = "DELETE_ENTITY_RELATION",
  ViewEntityPipelineRelation = "VIEW_ENTITY_PIPELINE_RELATION",
  ViewEntityQueryRelation = "VIEW_ENTITY_QUERY_RELATION",

  ViewEntityAccess = "VIEW_ENTITY_ACCESS",
  AddEntityAccess = "ADD_ENTITY_ACCESS",
  EditEntityAccess = "EDIT_ENTITY_ACCESS",
  DeleteEntityAccess = "DELETE_ENTITY_ACCESS",

  ViewEntityArchive = "VIEW_ENTITY_ARCHIVE",
  EditEntityArchive = "EDIT_ENTITY_ARCHIVE",
  ViewArchiveHistory = "VIEW_ARCHIVE_HISTORY",
  ExportEntityMeta = "EXPORT_ENTITY_META",
  ImportEntityMeta = "IMPORT_ENTITY_META",

  ViewEntityRecycleBin = "VIEW_ENTITY_RECYCLE_BIN",
  RecoverEntity = "RECOVER_ENTITY",
  PurgeEntity = "PURGE_ENTITY",

  DataPipelinePage = "DATA_PIPELINE_PAGE",

  AddPipeline = "ADD_PIPELINE",
  EditPipeline = "EDIT_PIPELINE",
  DeletePipeline = "DELETE_PIPELINE",
  StartPipeline = "START_PIPELINE",
  StopPipeline = "STOP_PIPELINE",
  ViewPipelineConsole = "VIEW_PIPELINE_CONSOLE",
  ClonePipeline = "CLONE_PIPELINE",
  ExportPipelineMeta = "EXPORT_PIPELINE_META",
  ImportPipelineMeta = "IMPORT_PIPELINE_META",
  PipelineHistory = "VIEW_PIPELINE_HISTORY",
  DownloadPipelineErrorReport = "DOWNLOAD_PIPELINE_ERROR_REPORT",

  PipelinePreviewRun = "VIEW_PIPELINE_PREVIEW_RUN",
  EditPipelinePreviewRun = "EDIT_PIPELINE_PREVIEW_RUN",
  StartPipelinePreviewRun = "START_PIPELINE_PREVIEW_RUN",

  WorkflowControl = "VIEW_WORKFLOW_CONTROL",
  EditWorkflowControl = "EDIT_WORKFLOW_CONTROL",
  StartWorkflowControl = "START_WORKFLOW_CONTROL",
  StopWorkflowControl = "STOP_WORKFLOW_CONTROL",

  ViewPipelineAccess = "VIEW_PIPELINE_ACCESS",
  AddPipelineAccess = "ADD_PIPELINE_ACCESS",
  EditPipelineAccess = "EDIT_PIPELINE_ACCESS",
  DeletePipelineAccess = "DELETE_PIPELINE_ACCESS",

  ViewPipelineRecycleBin = "VIEW_PIPELINE_RECYCLE_BIN",
  RecoverPipeline = "RECOVER_PIPELINE",
  PurgePipeline = "PURGE_PIPELINE",

  UserGroup = "USER_GROUP",

  AddUser = "ADD_USER",
  EditUser = "EDIT_USER",
  DeleteUser = "DELETE_USER",
  AddGroup = "ADD_GROUP",
  EditGroup = "EDIT_GROUP",
  DeleteGroup = "DELETE_GROUP",
  AccessRightManagement = "ACCESS_RIGHT_MANAGEMENT",

  AccessControl = "ACCESS_CONTROL",
  EditAccessControl = "EDIT_ACCESS_CONTROL",

  SmartQueryPage = "SMART_QUERY_PAGE",

  ViewSmartQuery = "VIEW_SMART_QUERY",
  ExportToFile = "EXPORT_TO_FILE",
  DownloadQueryErrorReport = "DOWNLOAD_QUERY_ERROR_REPORT",
  AddSmartQuery = "ADD_SMART_QUERY",
  EditSmartQuery = "EDIT_SMART_QUERY",
  DeleteSmartQuery = "DELETE_SMART_QUERY",
  PreviewSmartQuery = "PREVIEW_SMART_QUERY",
  StartSmartQuery = "START_SMART_QUERY",
  StopSmartQuery = "STOP_SMART_QUERY",

  ViewQueryAccess = "VIEW_QUERY_ACCESS",
  AddQueryAccess = "ADD_QUERY_ACCESS",
  EditQueryAccess = "EDIT_QUERY_ACCESS",
  DeleteQueryAccess = "DELETE_QUERY_ACCESS",

  SmartSearchPage = "SMART_SEARCH_PAGE",

  ChatbotPage = "CHATBOT_PAGE",
  CrawlerPage = "CRAWLER_PAGE",
  ContentHubPage = "CONTENT_HUB_PAGE",
  ChatbotCustomizationPage= "CHATBOT_CUSTOMIZATION_PAGE",

  DataComparatorPage = "DATA_COMPARATOR_PAGE",

  MyProfilePage = "MY_PROFILE_PAGE",

  PersonalInfo = "PERSONAL_INFO",
  ChangePassword = "CHANGE_PASSWORD",
  ArchiveSetting = "ARCHIVE_SETTING",
  NotificationSetting = "NOTIFICATION_SETTING",

  AdminSettingPage = "ADMIN_SETTING_PAGE",
  ViewSmartSearchSetting = "VIEW_SMART_SEARCH_SETTING",
  AddSmartSearchSetting = "ADD_SMART_SEARCH_SETTING",
  EditSmartSearchSetting = "EDIT_SMART_SEARCH_SETTING",
  DeleteSmartSearchSetting = "DELETE_SMART_SEARCH_SETTING",

  ViewSearchAccess = "VIEW_SEARCH_ACCESS",
  AddSearchAccess = "ADD_SEARCH_ACCESS",
  EditSearchAccess = "EDIT_SEARCH_ACCESS",
  DeleteSearchAccess = "DELETE_SEARCH_ACCESS",
  NotificationUserSetting = "NOTIFICATION_USER_SETTING",
  ViewWatchdogSetting = "VIEW_WATCHDOG_SETTING",
}
