import {Injectable} from "@angular/core";
import {ContextDataService} from "../../global-services/context-data.service";
import {GlobalErrorHandler} from "../../global-services/error-handler.service";
import {EnumUserRole} from "../../enums/user-role.enum";

@Injectable({
  providedIn: 'root'
})
export class RoleAccessHelper {

  isEnabledAccess(roleAccess): boolean {
    const newArray = ContextDataService.roleAccess.filter(function (ra) {
      return ra['functionName'] === roleAccess;
    });
    if (newArray == undefined) return false;
    return newArray[0]['enabled'];
  }

  roleAccessNotSetError(role) {
    if (role === EnumUserRole.SystemAdmin) {
      GlobalErrorHandler.info("Restricted Access!!",
        `Sorry, ${role} access right setting is not present'. Please restart the backend program.`,
        () => {}
      );
      return;
    }

    let authorizeRole: string = (role === EnumUserRole.OrganizationAdmin)? EnumUserRole.SystemAdmin : EnumUserRole.OrganizationAdmin;

    GlobalErrorHandler.info("Restricted Access!!",
      `Sorry, ${role} access right setting is not present'. Please notify your ${authorizeRole} for further access.`,
      () => {}
    );
  }

  roleAccessErrorHandling(roleAccess) {
    GlobalErrorHandler.info("Restricted Access!!",
      `Sorry, you are not authenticated to '${roleAccess}'. Please notify your organization admin for further access.`,
      () => console.log("Confirmed !")
    );
  }
}
