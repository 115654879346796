<!--begin::Topbar-->

<ng-container *ngIf="extrasUserLayout === 'offcanvas'">
  <ng-container *ngIf="user">
    <!--    <ng-container *ngIf="user$ | async as _user">-->
    <!--begin::User-->
    <div class="topbar-item">
      <div class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2"
           id="kt_quick_user_toggle">
        <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
        <span
          class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{ user.userName }}</span>
        <span class="symbol symbol-lg-35 symbol-25 symbol-light-danger">
            <span class="symbol-label font-size-h5 font-weight-bold role-color" style="width: 100%;height: auto;    font-size: 10px !important;
    padding: 6px;">{{
              user.userRole | textCase
              }}</span>
          </span>

      </div>
      <div class="btn btn-clean btn-lg">
        <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
          {{now | date: 'dd/MM/yyyy(EEE) - hh:mm:ss': userTimezone}} ({{userTimezone}})
        </span>
      </div>
    </div>
    <!--end::User-->
  </ng-container>
</ng-container>


<div class="topbar-item">
  <!--<ng-container *ngIf="extraSearchDisplay">-->
  <!--  <ng-container *ngIf="extrasSearchLayout === 'offcanvas'">-->
  <!--    &lt;!&ndash;begin::Search&ndash;&gt;-->
<!--    <div class="topbar-item">-->
  <!--      <div class="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_search_toggle">-->
  <!--        <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-2.svg'" cacheSVG="true"-->
  <!--              class="svg-icon svg-icon-xl xx secondary"></span>-->
<!--      </div>-->
<!--    </div>-->
  <!--    &lt;!&ndash;end::Search&ndash;&gt;-->
<!--  </ng-container>-->

  <!--  <div id="kt_quick_search_toggle">-->
  <!--    &lt;!&ndash;begin::Toggle&ndash;&gt;-->
  <!--    <div class="topbar-item">-->
  <!--      <a class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1" routerLink="/account/my-profile/{{user.userID}}"-->
  <!--         routerLinkActive="active">-->
  <!--            <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-2.svg'" cacheSVG="true"-->
  <!--                  class="svg-icon svg-icon-xl svg-icon-danger"></span>-->
  <!--      </a>-->
  <!--    </div>-->
  <!--    &lt;!&ndash;end::Toggle&ndash;&gt;-->
  <!--  </div>-->
<!--</ng-container>-->

  <!--API Specifier-->
  <div id="kt_api_specifier_toggle">
    <!--begin::Toggle-->
    <div class="topbar-item">
      <a class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1"
         (click)="openApi()"
      >
          <span [inlineSVG]="'./assets/media/svg/icons/Code/Api.svg'" cacheSVG="true"
                class="svg-icon svg-icon-xl svg-icon-danger api-color" ></span>
      </a>
    </div>
    <!--end::Toggle-->
  </div>





  <div id="kt_document_toggle">
    <!--begin::Toggle-->
    <div class="topbar-item">
      <a class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1"
         (click)="openDoc()"
      >
        <i class="fa fa-file-text text-danger" aria-hidden="true"></i>
      </a>
    </div>
    <!--end::Toggle-->
  </div>

  <ng-container *ngIf="extrasNotificationsDisplay">
    <!--  <ng-container *ngIf="extrasNotificationsLayout === 'offcanvas'">-->
    <!--    &lt;!&ndash;begin::Notifications&ndash;&gt;-->
<!--    <div class="topbar-item">-->
    <!--      <div class="btn btn-icon btn-icon-mobile btn-clean btn-lg mr-1 pulse pulse-primary"-->
    <!--        id="kt_quick_notifications_toggle">-->
    <!--          <span [inlineSVG]="'./assets/media/svg/icons/Home/Bulb1.svg'" cacheSVG="true"-->
    <!--            class="svg-icon svg-icon-xl xx secondary"></span>-->
    <!--          <span class="pulse-ring"></span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    &lt;!&ndash;end::Notifications&ndash;&gt;-->
    <!--  </ng-container>-->

    <ng-container *ngIf="extrasNotificationsLayout === 'dropdown'">
      <!--begin::Notifications-->
      <div class="dropdown" ngbDropdown placement="bottom-right" (openChange)="checkDropdown($event)">
        <!--begin::Toggle-->
        <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" ngbDropdownToggle>
          <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-danger">
          <span [inlineSVG]="'./assets/media/svg/icons/Home/Bulb1.svg'" cacheSVG="true"
                class="svg-icon svg-icon-xl svg-icon-danger">
          </span>
          <span *ngIf="notificationNum > 0" class="badge badge-danger top-bar-badge" style="min-width: 18px;padding: 4px;">
            {{notificationNum}}
          </span>
          <span class="pulse-ring"></span>
          </div>
        </div>
        <!--end::Toggle-->

        <!--begin::Dropdown-->
        <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-lg"
             style="margin-left: -90px !important; z-index: 100 !important; box-shadow: 0 0 5px rgb(33 33 33 / 50%)">
          <form>
            <app-notifications-dropdown
              [alertNotice]="alertNotice"
              [eventNotice]="eventNotice"
              [logNotice]="logNotice"
              [noticeSetting]="noticeSetting"
              [notificationNum]="notificationNum"
              (notificationUpdateNum)="updateNotificationNum($event)"
            ></app-notifications-dropdown>
          </form>
        </div>
        <!--end::Dropdown-->
      </div>
      <!--end::Notifications-->
    </ng-container>
  </ng-container>
  <div id="kt_logout_toggle">
    <!--begin::Toggle-->
    <div class="topbar-item">
      <a class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1"
         (click)="logout()"
      >
          <span [inlineSVG]="'./assets/media/svg/icons/Navigation/Sign-out.svg'" cacheSVG="true"
                class="svg-icon svg-icon-xl svg-icon-danger"></span>
      </a>
    </div>
    <!--end::Toggle-->
  </div>
  <!--<ng-container *ngIf="extrasQuickActionsDisplay">-->
  <!--  <ng-container *ngIf="extrasQuickActionsLayout === 'offcanvas'">-->
  <!--    &lt;!&ndash;begin::Quick Actions&ndash;&gt;-->
<!--    <div class="topbar-item">-->
  <!--      <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1" id="kt_quick_actions_toggle">-->
  <!--        <span [inlineSVG]="'./assets/media/svg/icons/Media/Equalizer.svg'" cacheSVG="true"-->
  <!--          class="svg-icon svg-icon-xl xx secondary"></span>-->
  <!--      </div>-->
<!--    </div>-->
  <!--    &lt;!&ndash;end::Quick Actions&ndash;&gt;-->
<!--  </ng-container>-->
  <!--  <ng-container *ngIf="extrasQuickActionsLayout === 'dropdown'">-->
  <!--    &lt;!&ndash;begin::Quick Actions&ndash;&gt;-->
<!--    <div class="dropdown" ngbDropdown placement="bottom">-->
<!--      &lt;!&ndash;begin::Toggle&ndash;&gt;-->
<!--      <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" ngbDropdownToggle>-->
<!--        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">-->
  <!--          <span [inlineSVG]="'./assets/media/svg/icons/Media/Equalizer.svg'" cacheSVG="true"-->
  <!--            class="svg-icon svg-icon-xl xx secondary"></span>-->
  <!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Toggle&ndash;&gt;-->
<!--      &lt;!&ndash;begin::Dropdown&ndash;&gt;-->
  <!--      <div class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-lg" ngbDropdownMenu>-->
  <!--        <app-quick-actions-dropdown-inner></app-quick-actions-dropdown-inner>-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Dropdown&ndash;&gt;-->
<!--    </div>-->
  <!--    &lt;!&ndash;end::Quick Actions&ndash;&gt;-->
<!--  </ng-container>-->
<!--</ng-container>-->

<!--  <ng-container *ngIf="extrasCartDisplay">-->
<!--    <ng-container *ngIf="extrasCartLayout === 'offcanvas'">-->
<!--      &lt;!&ndash;begin::Cart&ndash;&gt;-->
<!--      <div class="topbar-item">-->
<!--        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1" id="kt_quick_cart_toggle">-->
<!--        <span [inlineSVG]="'./assets/media/svg/icons/Shopping/Cart3.svg'" cacheSVG="true"-->
<!--              class="svg-icon svg-icon-xl xx secondary"></span>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Cart&ndash;&gt;-->
<!--    </ng-container>-->
<!--    <ng-container *ngIf="extrasCartLayout === 'dropdown'">-->
<!--      &lt;!&ndash;begin::Cart&ndash;&gt;-->
<!--      <div class="dropdown" ngbDropdown placement="bottom">-->
<!--        &lt;!&ndash;begin::Toggle&ndash;&gt;-->
<!--        <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" ngbDropdownToggle>-->
<!--          <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">-->
<!--          <span [inlineSVG]="'./assets/media/svg/icons/Shopping/Cart3.svg'" cacheSVG="true"-->
<!--                class="svg-icon svg-icon-xl xx secondary"></span>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash;end::Toggle&ndash;&gt;-->
<!--        &lt;!&ndash;begin::Dropdown&ndash;&gt;-->
<!--        <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-xl dropdown-menu-anim-up">-->
<!--          <form>-->
<!--          </form>-->
<!--        </div>-->
<!--        &lt;!&ndash;end::Dropdown&ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Cart&ndash;&gt;-->
<!--    </ng-container>-->
<!--  </ng-container>-->

<!--  <ng-container *ngIf="extrasQuickPanelDisplay">-->
<!--    &lt;!&ndash;begin::Quick panel&ndash;&gt;-->
<!--    <div class="topbar-item">-->
<!--      <div class="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_panel_toggle">-->
<!--      <span [inlineSVG]="'./assets/media/svg/icons/Layout/Layout-4-blocks.svg'" cacheSVG="true"-->
<!--            class="svg-icon svg-icon-xl xx secondary"></span>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash;end::Quick panel&ndash;&gt;-->
<!--  </ng-container>-->

  <!--<ng-container *ngIf="extrasLanguagesDisplay">-->
  <!--  <app-language-selector style="margin-top: 10px;"></app-language-selector>-->
  <!--</ng-container>-->


  <!--end::Topbar-->
</div>
