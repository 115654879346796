import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SplashScreenModule } from './shared/components/splash-screen/splash-screen.module';
import { ThemeComponent } from './theme/theme.component';
// #fake-start#
import { MatNativeDateModule } from "@angular/material/core";
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from "@angular/material/menu";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { AgGridModule } from "ag-grid-angular";
import { BreadCrumbService } from "./global-services/bread-crumb.service";
import { PendingChangesGuard } from "./global-services/can-deactivate-guard.service";
import { CanDeactivateService } from "./global-services/can-deactivate.service";
import { DataTableAjaxService } from "./global-services/data-table-ajax.service";
import { GlobalErrorHandler } from "./global-services/error-handler.service";
import { GlobalHttpInterceptorProvider } from "./global-services/global-http-interceptor.service";
import { RouteParamsService } from "./global-services/route-params.service";
import { RouteService } from "./global-services/route.service";
import { ScriptLoaderService } from "./global-services/script-loader.service";
import { SweetAlertService } from "./global-services/sweet-alert.service";
import { ToastrService } from "./global-services/toastr.service";
import { SplashRoutingModules } from "./shared/components/splash-routing/splash-routing.modules";
import { LayoutModule } from "./theme/_layout/layout.module";
import { ContextDataResolver } from "./theme/context-data.resolver";
import { ThemeRoutingModule } from "./theme/theme-routing.module";

@NgModule({
  declarations: [AppComponent, ThemeComponent,], //Declares which belong to this module
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    SplashRoutingModules,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    LayoutModule,
    ThemeRoutingModule,
    AgGridModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatDialogModule,
  ],
  providers: [
    ScriptLoaderService,
    ToastrService,
    GlobalHttpInterceptorProvider,
    SweetAlertService,
    BreadCrumbService,
    CanDeactivateService,
    RouteParamsService,
    DataTableAjaxService,
    RouteService,
    PendingChangesGuard,
    ContextDataResolver,
    GlobalErrorHandler,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        languages: {
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
